import type { Client } from '@urql/vue';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) return; // Ensures this runs only in the browser

  const id = to.params.id as string;
  const $apiClient = useNuxtApp().$apiClient as Client;
  const result = await $apiClient.query(`
    query Publication($id: ID!) {
      publication(id: $id) {
        id
      }
    }
  `, { id }).toPromise();
  return await handleGraphqlMiddlewareResponse(result, id, 'publication');
});
